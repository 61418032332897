$(document).foundation();

$(document).ready(function() {




    if (Stickyfill) {
      var elements = document.querySelectorAll('.sticky');
      Stickyfill.add(elements);
    }

    // Object Fit fallback for IE & Edge
    if ($("html").hasClass("no-object-fit")){
        $('.home-gallery ul li , .overlay-img-link a p , .slide').addClass('img-fit');
    }
    $('.no-object-fit .img-fit').each(function () {
      var $container = $(this),
          imgUrl = $container.find('img').prop('src');
      if (imgUrl) {
        $container
          .css('backgroundImage', 'url(' + imgUrl + ')')
          .addClass('compat-object-fit');
      }
    });

    // Call AOS - provides animations on scroll
    AOS.init({
        disable: window.innerWidth < 1025,
        once: true,
        duration: 1000,
    });

    // Add class to header once scrolled past X pixels
    function headerClass() {
        //$(document.body).css("margin-top", $("header").height());
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
            $("header").removeClass("header-scrolled");
        }else {
            $("header").addClass("header-scrolled");
        }
    }

    // Update header function on resize
    $(window).resize(function() {
        headerClass();
    });

    // Update header function on scroll
    $(window).scroll(function() {
        headerClass();
    });

    // Run Header Function on Doc ready
    headerClass();

    // Add class to body for content pages with no slideshow images
    if ($("body").hasClass("content-page") && $g < 10){
        $("#header").addClass("no-slide");
        $("body").addClass("body-no-slide");
    }

    // Owl Carousels
    $(".main-slider").owlCarousel({
        nav: true,
        navText : ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
        loop: $('.main-slider').children().length > 1,
        items: 1,
        autoplay: true,
        smartSpeed: 1000,
        addClassActive: true,
        onChanged: function (event) {
		    setTimeout(function(){
		        $('.main-slider').find('.active .overlay').addClass('animated')
                $('.main-slider').find('.active').siblings().find('.overlay').removeClass('animated')
		    }, 100);
        }
    });

    $('.explore-slider ul').owlCarousel({
        nav: true,
        navText : ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
        dots: true,
        loop: false,
        items: 1,
        autoplay: true,
    });

    $('#reviews ul').owlCarousel({
        navigation: true,
        dots: true,
        loop: true,
        items: 1,
        autoplay: true,
    });


    // Magnific
	$('.popup-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		tLoading: 'Loading image #%curr%...',
		mainClass: 'mfp-img-mobile',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0,1], // Will preload 0 - before current, and 1 after the current image
            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"><i class="fa fa-angle-%dir% mfp-prevent-close"></i></span>',
		},
		image: {
			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
			titleSrc: function(item) {
				return item.el.attr('title');
			}
		}
	});

    // Back to top button
    $('.scroll-fix').click(function() {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

    $('#page-content a[href*=#]').click(function(event){  
      $('html, body').animate({
          scrollTop: $( $.attr(this, 'href') ).offset().top
      }, 1000);
      event.preventDefault();
    });

    // Scroll down button (Twice because of the header changing height)
    $('#scrolldown').click(function() {

        var scrolldownTarget = $('#scrolldown-target');
        $('html, body').animate({scrollTop: $( scrolldownTarget ).offset().top * 0.75 }, {
            duration: "300",
            easing: $.bez([.42, 0, 1, 1]),
            complete: function() {
                $('html, body').animate({scrollTop: ($( scrolldownTarget ).offset().top - $('header').height()) + 1 }, 'slow', $.bez([0,0,.58,1]));
            },
        });
    });


    $('ul.thumbs').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1], // Will preload 0 - before current, and 1 after the current image
            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"><i class="fa fa-angle-%dir% mfp-prevent-close"></i></span>',
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
                var caption =
                    '<div id="gallery-caption">';
                    if (item.el.attr('title') != undefined) {
                        caption += item.el.attr('title');
                    }
                    caption += '</div>';
                    if (item.el.attr('gallery-credit') != undefined) {
                        caption += '<div id="gallery-credit">';
                        caption += item.el.data('credit');
                        caption += '</div>';
                    }
            }
        }
    });



});

$(window).load(function() {
    AOS.init();
});
